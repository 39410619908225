import axios from "axios";
import { blendModuleAPI } from "../../../config";
import api from "../Requisition/api";
export default {
  async saveBlendPlanDetail(id, data) {
    return await api.saveBlendPlanDetail(id, data);
  },

  async getProvisionalPlan() {
    return await axios
      .get(
        `${blendModuleAPI}/v1/BlendProductionPlanMaster/GetProvisionalBlends`
      )
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async approveBlendPlan(id) {
    try {
      const { data } = await axios.put(
        `${blendModuleAPI}/v1/BlendProductionPlanMaster/Approve/${id}`
      );
      return data.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },

  async cancelBlendPlan(id) {
    try {
      const { data } = await axios.put(
        `${blendModuleAPI}/v1/BlendProductionPlanMaster/Cancel/${id}`
      );
      return data.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },

  async downloadProvisionalPlan(id, planCode) {
    axios
      .get(
        `${blendModuleAPI}/v1/BlendProductionPlanDetail/Export?planMasterId=${id}&fields=BatchNo&fields=ItemName&fields=MarkName&fields=GradeName&fields=GPDate&fields=PurchasedRate&fields=TotalNetKgs&fields=NoOfPackages&fields=CompanyWarehouseName&fields=PurchaseType&fields=InvNo`,
        {
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        this.forceFileDownload(response, `${planCode} - Provisional Plan`);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  forceFileDownload(response, fileName) {
    let date_time = new Date();

    date_time = `${(date_time.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date_time
      .getDate()
      .toString()
      .padStart(2, "0")}/${date_time
      .getFullYear()
      .toString()
      .padStart(4, "0")} ${date_time
      .getHours()
      .toString()
      .padStart(2, "0")}:${date_time
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${date_time.getSeconds().toString().padStart(2, "0")}`;

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
  },
};
