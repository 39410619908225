import axios from "axios";
import { blendModuleAPI } from "../../../config";

export default {
  async getBlendProductionPlanMaster() {
    return await axios
      .get(`${blendModuleAPI}/v1/BlendProductionPlanMaster/GetRMRequisitions`)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async getBlendPlanQtyDetails(id) {
    return await axios
      .get(`${blendModuleAPI}/v1/BlendProductionPlanMaster/GetRequiredQuantity/${id}`)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async createProductionPlanMaster(data) {
    try {
      const res = await axios.post(`${blendModuleAPI}/v1/BlendProductionPlanMaster`, data);
      return res.data.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  async updateProductionPlanMaster(data) {
    try {
      const res = await axios.put(`${blendModuleAPI}/v1/BlendProductionPlanMaster/${data.id}`, data);
      return res.data.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async getAvailableStockForProduction(data) {
    try {
      const res = await axios.post(`${blendModuleAPI}/v1/BlendProductionPlanGenerator/GetStock`, data);

      // Lopping through response data to modify
      // Changing Stock KG Shown in UI to total available KG/total allocated KG
      // Changing no bags Shown in UI to (available KG/total allocated KG) / netKG
      return res.data.data.map((item) => {
        item.totalNetKgs = (Number(item.totalNetKgs) - Number(item.allocatedNetKgs)).toFixed(2);
        item.allocatedNetKgs = Number(item.allocatedNetKgs).toFixed(2);
        item.noOfPackages = Math.ceil(item.totalNetKgs / item.netKgs);
        return item;
      });
    } catch (error) {
      throw error.response.data.message;
    }
  },

  async saveBlendPlanDetail(id, data) {
    const _bodyArray = [];

    for (const item of data) {
      const newBody = {
        noOfPackages: item.requiredPackages,
        totalNetKgs: item.requiredQuantity,
        blendProductionPlanId: item.blendProductionPlanId,
        inventoryId: item.inventoryId,
        purchaseChildId: item.purchaseChildId,
        batchNo: item.batchNo,
        netKgs: item.netKgs,
        invNo: item.invNo,
        markId: item.markId,
        markName: item.markName,
        gradeName: item.gradeName,
        gpDate: item.gpDate,
        bay: item.bay,
        itemName: item.itemName,
        purchasedRate: item.purchasedRate,
        companyWarehouseId: item.companyWarehouseId,
        companyWarehouseName: item.companyWarehouseName,
        purchaseType: item.purchaseType,
      };
      if (item.requiredQuantity || parseInt(item.requiredQuantity) > 0) {
        _bodyArray.push(newBody);
      }
    }

    try {
      const res = await axios.post(`${blendModuleAPI}/v1/BlendProductionPlanDetail/SaveByMasterId/${id}`, _bodyArray);
      return res.data.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async getBlendPlanDetail(id) {
    try {
      const { data } = await axios.get(`${blendModuleAPI}/v1/BlendProductionPlanDetail/GetByMasterId/${id}`);
      return data.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  async generateBlendPlan(body) {
    try {
      const { data } = await axios.post(`${blendModuleAPI}/v1/BlendProductionPlanGenerator/GeneratePlan`, body);
      return data.data;
    } catch (error) {
      throw error.response.data.message.split(":")[1];
    }
  },

  async reArrangeBlendPlan(id) {
    try {
      const { data } = await axios.get(`${blendModuleAPI}/v1/BlendProductionPlanGenerator/ReassignPlan?planMasterId=${id}`);
      return data.data;
    } catch (error) {
      throw error.response.data.message.split(":")[1];
    }
  },

  async generateProvisionPlan(id) {
    try {
      const { data } = await axios.put(`${blendModuleAPI}/v1/BlendProductionPlanMaster/Generate/${id}`);
      return data.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },

  async downloadBlendPlan(id, planCode) {
    axios
      .get(
        `${blendModuleAPI}/v1/BlendProductionPlanDetail/Export?planMasterId=${id}&fields=BatchNo&fields=ItemName&fields=MarkName&fields=GradeName&fields=GPDate&fields=PurchasedRate&fields=TotalNetKgs&fields=NoOfPackages&fields=CompanyWarehouseName&fields=PurchaseType&fields=InvNo`,
        {
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        this.forceFileDownload(response, `${planCode} - RM Requisition`);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  forceFileDownload(response, fileName) {
    let date_time = new Date();

    date_time = `${(date_time.getMonth() + 1).toString().padStart(2, "0")}/${date_time.getDate().toString().padStart(2, "0")}/${date_time
      .getFullYear()
      .toString()
      .padStart(4, "0")} ${date_time.getHours().toString().padStart(2, "0")}:${date_time
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${date_time.getSeconds().toString().padStart(2, "0")}`;

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
  },
};
